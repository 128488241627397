import * as React from "react"
import styled from "styled-components"
import { devices, dimensions } from "../scss/js-variables"

import { StaticImage } from "gatsby-plugin-image"

const DonDelicja = props => {
  return (
    <StyledDonDelicja className={props.className ? props.className : ""}>
      <div>
        <DonDelicjaPng />
      </div>
    </StyledDonDelicja>
  )
}

export default DonDelicja

const DonDelicjaPng = () => (
  <StaticImage
    src="../images/don_dona.png"
    alt="Don delicja"
    width={1000}
    objectFit="contain"
    placeholder="BLURRED"
  />
)

const StyledDonDelicja = styled.div`
  position: sticky;
  z-index: 0;
  bottom: 0px;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  line-height: 0;
  overflow: hidden;
  margin-top: -100vh;
  &.fix-secound-section {
    @media ${devices["$pcB"]} {
      margin-top: -150vh;
      margin-bottom: 50vh;
      box-shadow: 0 0 16em #000000ad;
    }
  }
  > div {
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 110vw;
    text-align: center;
    @media ${devices["$tabletA"]} {
      left: 20%;
      width: 100vw;
    }
    @media ${devices["$large-tabletA"]} {
      position: static;
      width: 65%;
      margin-left: auto;
    }
  }
  svg,
  img {
    max-width: 100%;
    max-height: 100vh;
    padding-top: ${dimensions["navHeight"]};
  }
`
