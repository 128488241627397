import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { devices, text } from "../../scss/js-variables"

import CloseButton from "../../../assets/x.svg"

const Cookies = ({ className }) => {
  const cookieExppire = 60 * 60 // one hour

  const [displayCookieNotice, setDisplayCookieNotice] = useState(false)

  function setCookie(name, value, expire, domain) {
    let domain_string = domain ? "; domain=" + domain : ""
    document.cookie =
      name +
      "=" +
      encodeURIComponent(value) +
      "; max-age=" +
      expire +
      "; path=/" +
      domain_string
  }

  function handleChideCookie() {
    setDisplayCookieNotice(!displayCookieNotice)
    setCookie("displayCookieNotice", false, cookieExppire)
  }

  useEffect(() => {
    if (!cookieExist("displayCookieNotice")) {
      setDisplayCookieNotice(true)
    }
  }, [displayCookieNotice])

  return (
    <>
      {displayCookieNotice && (
        <StyledCookies
          id="cookies"
          className={"container cookies  " + (className ? className : "")}
        >
          <div className="text-wrapper">
            <div className="title">¡Me gustan las Cookies!</div>
            <div className="content">
              <p>
                Nuestros productos se venden como Rosquillas... Y&nbsp;aqui
                recopilamos cookies para que te resulte más fácil utilizar
                nuestro sitio web.
              </p>
            </div>
          </div>
          <div className="button-wrapper">
            <StyledCloseButton onClick={handleChideCookie} />
          </div>
        </StyledCookies>
      )}
    </>
  )
}

const StyledCookies = styled.section`
  position: fixed;
  z-index: 99;
  background: white;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85em;
    flex-wrap: wrap;
    @media ${devices["$tabletA"]} {
      flex-wrap: nowrap;
      font-size: 1em;
    }
    .title {
      font-size: 1.4em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    .content {
      padding-left: 0em;
      padding-right: 1em;
      @media ${devices["$tabletA"]} {
        max-width: 60%;
        padding-left: 1em;
        margin-left: auto;
      }
      p {
        margin: 0;
        @media ${devices["$large-tabletB"]} {
          line-height: 1.4em;
        }
      }
    }
  }
  .button-wrapper {
    min-width: 2em;
  }
`
const StyledCloseButton = styled(CloseButton)`
  path {
    transition: 0.3s fill linear;
  }
  &:hover {
    cursor: pointer;
    path {
      fill: ${text["link-hover"]};
    }
  }
`
const cookieExist = name => {
  var dc = document.cookie
  var prefix = name + "="
  var begin = dc.indexOf("; " + prefix)
  if (begin === -1) {
    begin = dc.indexOf(prefix)
    if (begin !== 0) return null
  } else {
    begin += 2
    var end = document.cookie.indexOf(";", begin)
    if (end !== -1) {
      end = dc.length
    }
  }
  return decodeURI(dc.substring(begin + prefix.length, end))
}

// const getCookie = name => {
//   const value = `; ${document.cookie}`
//   const parts = value.split(`; ${name}=`)
//   if (parts.length === 2) return parts.pop().split(";").shift()
// }

Cookies.propTypes = {
  className: PropTypes.string,
}

Cookies.defaultProps = {
  className: "",
}

export default Cookies
