import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import styled from "styled-components"
import { colors, devices, text, dimensions } from "../scss/js-variables"

import Logo from "../../assets/logo.svg"
import Hamburger from "../../assets/hamburger.svg"
// import Pt from "../../assets/pt.svg"
// import Es from "../../assets/es.svg"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const Header = props => {
  const [prapareAnimation, setPrapareAnimation] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  const [navTimeline, setNavTimeline] = useState(null)

  // refs
  const hamburgerRef = useRef()
  const overlayBoxRef = useRef()
  const navBoxRef = useRef()
  const mainNavBoxRef = useRef()
  // const langNavBoxRef = useRef()
  const svgLogoRef = useRef()

  // animation elements

  const PrepareAnimation = (
    hamburgerParam,
    overlayBoxParam,
    navBoxParam,
    mainNavBoxParam
    // langNavBoxParam
  ) => {
    // const [hamburger, overlayBox, navBox, mainNavBox, langNavBox] = [
    const [hamburger, overlayBox, navBox, mainNavBox] = [
      hamburgerParam,
      overlayBoxParam,
      navBoxParam,
      mainNavBoxParam,
      // langNavBoxParam,
    ]
    gsap.set(navBox, { autoAlpha: 0 })
    gsap.set(overlayBox, { autoAlpha: 0 })
    gsap.set(mainNavBox, { autoAlpha: 0, y: "-=25" })
    // gsap.set(langNavBox, { autoAlpha: 0, y: "-=25" })
    gsap.set(hamburger, {})
  }

  function showMenu() {
    setMenuOpen(true)
  }

  function achorLinkHandle(target = null) {
    hideMenu()
  }

  function hideMenu() {
    setMenuOpen(false)
  }

  function handleToogleManu() {
    if (menuOpen) {
      hideMenu()
    } else {
      showMenu()
    }
  }

  useEffect(() => {
    const logo = svgLogoRef.current.getElementsByTagName("svg")
    gsap.set(logo, { overflow: "visible" })
    gsap.set(logo[0].children, { autoAlpha: 1, scale: 1, y: 0 })
    const introLogo = gsap.from(logo[0].children, {
      autoAlpha: 0,
      scale: 0,
      stagger: 0.1,
    })

    function scene2() {
      let repeatLogoTl = gsap.timeline({
        repeat: -1,
        repeatDelay: 4,
        delay: 3,
        duration: 2,
      })
      Array.from(logo[0].children).forEach((logo, i) => {
        repeatLogoTl.add(
          gsap.to(logo, {
            y: "-=10",
            scale: "1.1",
            repeat: 1,
            yoyo: true,
            duration: 0.2,
          }),
          "-=0.35"
        )
      })
      return repeatLogoTl
    }

    const logoTimeline = gsap.timeline({})
    logoTimeline.add(introLogo)
    logoTimeline.add(scene2())
    logoTimeline.play()
  }, [])

  useEffect(() => {
    const [hamburger] = hamburgerRef.current.children
    const hamburgerOpen = hamburger.getElementById("hamburger")
    const hamburgerClose = hamburger.getElementById("x")
    const overlayBox = overlayBoxRef.current
    const navBox = navBoxRef.current
    const mainNavBox = mainNavBoxRef.current.children
    // const langNavBox = langNavBoxRef.current.children

    if (prapareAnimation) {
      // PrepareAnimation(hamburger, overlayBox, navBox, mainNavBox, langNavBox)
      PrepareAnimation(hamburger, overlayBox, navBox, mainNavBox)
      setPrapareAnimation(false)
    }
    const hamburgerTween = gsap.timeline({
      default: {
        ease: "elastic.out(1, 0.3)",
        duration: "0.5",
      },
    })
    hamburgerTween.fromTo(
      hamburgerOpen,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 1,
        scale: 1,
      },
      {
        transformOrigin: "50% 50%",
        autoAlpha: 0,
        scale: 0,
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      }
    )
    hamburgerTween.fromTo(
      hamburgerClose,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 0,
        scale: 0,
      },
      {
        transformOrigin: "50% 50%",
        autoAlpha: 1,
        scale: 1,
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      },
      "-=0.5"
    )

    const globalNavTl = gsap.timeline({
      default: { ease: "power3.inOut" },
    })
    globalNavTl.to(navBox, { autoAlpha: 1 })
    globalNavTl.to(overlayBox, { duration: 0.5, autoAlpha: 1 })
    // globalNavTl.to(
    //   langNavBox,
    //   { duration: 0.3, autoAlpha: 1, y: "0", stagger: 0.1 },
    //   "-=0.5"
    // )
    globalNavTl.to(
      mainNavBox,
      { duration: 0.4, autoAlpha: 1, y: "0", stagger: 0.1 },
      "-=0.2"
    )
    globalNavTl.add(hamburgerTween, "-=1")

    setNavTimeline(globalNavTl)
  }, [prapareAnimation])

  useEffect(() => {
    if (navTimeline) {
      if (menuOpen) {
        navTimeline.play()
      } else {
        navTimeline.reverse()
      }
    }
  }, [navTimeline, menuOpen])

  return (
    <>
      <StyledHeader className="container r :a-ec">
        <StyledLogo className="logo r c-7:xxs" ref={svgLogoRef}>
          <AnchorLink
            to="/#hola"
            aria-label="Link to home page"
            className="link-section"
            onAnchorLinkClick={() => {
              achorLinkHandle("#hola")
            }}
          >
            <Logo />
          </AnchorLink>
        </StyledLogo>
        <StyledNavigationWrapper
          className="navigation-wrapper r :w c-12:xxs c-12:xs c-12:sm :c-12:xxs :c-12:xs :c-12:sm :a-cc a-rc:sm+"
          ref={navBoxRef}
        >
          <ManiNavigation
            className="main r :a-cc"
            ref={mainNavBoxRef}
            onAnchorLinkClick={hideMenu}
          />
          {/* <LangNavigation className="lang r :a-cc" ref={langNavBoxRef} /> */}
        </StyledNavigationWrapper>
        <StyledHamburger
          className="hamburger"
          ref={hamburgerRef}
          onClick={handleToogleManu}
        >
          <Hamburger />
        </StyledHamburger>
      </StyledHeader>
      <StyledOverlay ref={overlayBoxRef} />
    </>
  )
}

const ManiNavigation = React.forwardRef((props, ref) => (
  <nav
    className={`main-navigation ${props.className ? props.className : ""}`}
    ref={ref}
  >
    <StyledAnchorLink to="/#hola" onAnchorLinkClick={props.onAnchorLinkClick}>
      ¡Hola!
    </StyledAnchorLink>
    <StyledAnchorLink
      to="/#dona-delicja"
      onAnchorLinkClick={props.onAnchorLinkClick}
    >
      Doña Delicia
    </StyledAnchorLink>
    <StyledAnchorLink
      to="/#galletas"
      onAnchorLinkClick={props.onAnchorLinkClick}
    >
      Galletas
    </StyledAnchorLink>
    <StyledAnchorLink
      to="/#contacto"
      onAnchorLinkClick={props.onAnchorLinkClick}
    >
      Contacto
    </StyledAnchorLink>
  </nav>
))

/*
const LangNavigation = React.forwardRef(({ className }, ref) => (
  <StyledLangMenu
    className={`"lang-navigation" + " " + ${className ? className : ""}`}
    ref={ref}
  >
     <li>
      <AnchorLink to="/">
        <Pt />
      </AnchorLink>
    </li>
    <li>
      <AnchorLink to="/">
        <Es />
      </AnchorLink>
    </li> 
  </StyledLangMenu>
))
*/

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${dimensions["navHeight"]};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  nav {
    margin-left: auto;
  }
`
const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: ${colors["color-bg4"]};
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  @media ${devices["$large-tabletA"]} {
    background-color: transparent;
    display: none;
  }
`
const StyledAnchorLink = styled(AnchorLink)`
  padding: 1em;
  text-align: center;
`
const StyledLogo = styled.div`
  height: 100%;
  a {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    order: 0;
  }
`
const StyledNavigationWrapper = styled.div`
  order: 2;
  color: ${text["white"]};
  @media ${devices["$pcB"]} {
    height: calc(100vh - ${dimensions["navHeight"]});
    overflow-y: scroll;
    flex-basis: 100%;
    nav {
      flex-direction: column;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    font-size: 2.4em;
  }
  @media only screen and (min-width: 992px) and (max-width: 1270px) {
    font-size: 0.8em;
  }
  @media ${devices["$large-tabletA"]} {
    color: ${text["black"]};
    order: 1;
    nav.main {
      flex-direction: row;
      order: 1;
    }
    .lang {
      order: 0;
    }
  }
`
const StyledHamburger = styled.div`
  cursor: pointer;
  order: 1;
  @media ${devices["$large-tabletA"]} {
    order: 2;
  }
  svg {
    padding: 0.5em;
  }
`
// const StyledLangMenu = styled.ul`
//   display: flex;
//   flex-wrap: nowrap;
//   li {
//     padding: 0.5em;
//   }
// `

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {
  className: "",
}

export default Header
