export const colors = {
  primary: "#000",
  "color-bg1": "#f3e9d3",
  "color-bg2": "#55baaf",
  "color-bg3": "#4d2929",
  "color-bg3_accent": "#4d2929",
  "color-bg4": "#de4b6c",
}
export const devices = {
  $phone: "only screen and (max-width: 479px)",
  $tablet: "only screen and (min-width: 479px) and (max-width: 768px)",
  "$large-tablet": "only screen and (min-width: 768px) and (max-width: 992px)",
  $pc: "only screen and (min-width: 992px) and (max-width: 1200px)",
  "$pc-large": "only screen and (min-width: 1200px)",

  $phoneA: "only screen and (min-width: 479px)",
  $tabletA: "only screen and (min-width: 768px)",
  "$large-tabletA": "only screen and (min-width: 992px)",
  $pcA: "only screen and (min-width: 1200px)",

  $tabletB: "only screen and (max-width: 479px)",
  "$large-tabletB": "only screen and (max-width: 768px)",
  $pcB: "only screen and (max-width: 992px)",
  "$pc-largeB": "only screen and (max-width: 1200px)",
}

export const text = {
  white: "#fff",
  black: "#000",
  "link-hover": "#55baaf",
  "text-section1": "#53998d",
  "text-section2": "#4d2929",
  "text-section3": " #4fc170",
  "text-section3_accent": " #c99796",
  "text-section4": "#fce1a8",
  "text-section4_accent": "#d6aa4d",
}
export const dimensions = {
  navHeight: "120px",
}
